import { binaryPrefixFormatter, fromRows, timeFormatter } from "@influxdata/giraffe"
import fp from "lodash/fp"

import type {
  Config as GiraffeConfig,
  ColumnType as GiraffeColumnType,
  Formatter as GiraffeFormatter,
  LineInterpolation,
  TimeFormatterFactoryOptions,
} from "@influxdata/giraffe"
import type { Palette } from "@mui/material"

import { formatXYPlotData } from "helpers/formatters/dataFormatters"
import { getXYPlotBounds } from "helpers/formatters/plots/lineChart"

import type {
  CellViewType,
  CustomProperties,
  RecordService,
  RecordType,
} from "types/dashboard.types"
import { customValueFormatter } from "helpers/formatters/plots/plots"

const DEFAULT_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"
const VIS_SIG_DIGITS = 3

function getAxisTicksOptions(options: CustomProperties) {
  const { generateXAxisTicks, generateYAxisTicks } = options
  const base = {
    xTotalTicks: undefined,
    xTickStart: undefined,
    xTickStep: undefined,
    yTotalTicks: undefined,
    yTickStart: undefined,
    yTickStep: undefined,
  }
  const xAxisTicks = fp.pick(generateXAxisTicks || [], options)
  const yAxisTicks = fp.pick(generateYAxisTicks || [], options)

  return { ...base, ...xAxisTicks, ...yAxisTicks }
}

interface FormatterParams {
  prefix?: string
  suffix?: string
  base?: string
  timeZone?: string
  trimZeros?: boolean
  timeFormat?: string
  decimalPlaces?: number
  formatUnits?: boolean
  locale?: string
}

function getFormatter(
  columnType: GiraffeColumnType | null,
  {
    prefix,
    suffix,
    base,
    timeZone,
    trimZeros = true,
    timeFormat = DEFAULT_TIME_FORMAT,
    decimalPlaces = VIS_SIG_DIGITS,
    formatUnits = true,
    locale,
  }: FormatterParams = {},
): GiraffeFormatter | null {
  if (columnType === "number") {
    if (base === "2") {
      return binaryPrefixFormatter({
        prefix,
        suffix,
        significantDigits: decimalPlaces,
        format: formatUnits,
      })
    }

    if (base === "10" || base === "") {
      return customValueFormatter({
        prefix,
        suffix,
        decimalPlaces,
        formatUnits,
        locale,
        trimZeros,
      })
    }
  }

  if (columnType === "time") {
    const formatOptions: TimeFormatterFactoryOptions = {
      timeZone: timeZone === "Local" ? undefined : timeZone,
      format: timeFormat || DEFAULT_TIME_FORMAT,
    }
    if (formatOptions.format?.includes("HH")) {
      formatOptions.hour12 = false
    }
    return timeFormatter(formatOptions)
  }

  return null
}

interface LineConfigParams {
  view: CellViewType
  data: RecordType[]
  recordService: RecordService
  palette: Palette
  locale?: string
}

interface GraphConfig {
  configuration: GiraffeConfig
  containerStyles: object
}

export default function lineConfig({
  view,
  data,
  recordService,
  palette,
  locale,
}: LineConfigParams): GraphConfig {
  const formattedData = formatXYPlotData(data)
  const xyTable = fromRows(formattedData)
  const [xDomain, yDomain] = getXYPlotBounds(view, formattedData)
  const fill = fp.filter(
    (column) => fp.some((row) => column in row, formattedData),
    recordService.getFill(),
  )

  const axisTicksOptions = getAxisTicksOptions(view.properties)

  const xColumn = view.properties.xColumn || "_time"
  const yColumn = view.properties.yColumn || "_value"

  // const timeZone = "UTC"
  const timeZone = "Local"

  const xFormatter = getFormatter(xyTable.getColumnType(xColumn), {
    prefix: view.properties.axes?.x.prefix,
    suffix: view.properties.axes?.x.suffix,
    base: view.properties.axes?.x.base,
    timeZone,
    timeFormat: view.properties.timeFormat,
    decimalPlaces: view.properties.decimalPlaces?.digits,
    formatUnits: view.properties.axes?.x.formatUnits,
    locale,
  })
  const yFormatter = getFormatter(xyTable.getColumnType(yColumn), {
    prefix: view.properties.axes?.y.prefix,
    suffix: view.properties.axes?.y.suffix,
    base: view.properties.axes?.y.base,
    timeZone,
    timeFormat: view.properties.timeFormat,
    decimalPlaces: view.properties.axes?.y.decimalPlaces?.digits,
    formatUnits: view.properties.axes?.y.formatUnits,
    locale,
  })

  const config: GraphConfig = {
    configuration: {
      ...view.properties,
      table: xyTable,

      valueFormatters: {
        ...(xFormatter ? { [xColumn]: xFormatter } : {}),
        ...(yFormatter ? { [yColumn]: yFormatter } : {}),
      },

      xDomain,
      includeXDomainZoom: true,
      onSetXDomain: () => {},
      onResetXDomain: () => {},

      yDomain,
      includeYDomainZoom: false,
      onSetYDomain: () => {},
      onResetYDomain: () => {},

      ...axisTicksOptions,

      gridColor: palette.text.secondary,
      gridOpacity: 0.2,
      staticLegend: { hide: true },
      legendOrientationThreshold: -1,
      legendColorizeRows: true,
      layers: [
        {
          type: "line",
          x: view.properties.xColumn || "_time",
          y: view.properties.yColumn || "_value",
          interpolation: view.properties.geom
            ? (view.properties.geom as LineInterpolation)
            : undefined,
          fill,
          colors: [
            palette.primary.main,
            palette.secondary.main,
            palette.warning.main,
            palette.text.secondary,
          ],
          lineWidth: 1.7,
        },
      ],
    },
    containerStyles: {
      height: "inherit",
    },
  }

  return config
}
