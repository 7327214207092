import type { FormatLocaleObject } from "d3-format"
import { format as d3Format } from "d3-format"
import { getLocaleFormat } from "features/d3Config"

interface ValueFormatter {
  value: number
  prefix?: string
  suffix?: string
  decimalPlaces?: number
  trimZeros?: boolean
  formatUnits?: boolean
  locale?: string
}

interface CustomValueFormatter {
  prefix?: string
  suffix?: string
  decimalPlaces?: number
  trimZeros?: boolean
  formatUnits?: boolean
  locale?: string
}

interface SIPrefixFormatter {
  decimalPlaces?: number
  trimZeros?: boolean
  localeFormat: FormatLocaleObject
}
/**
 * Functions below are based on [giraffe/src/utils/formatters.ts](https://github.com/influxdata/giraffe/blob/f8b9698044c522ce27f0c8d722f090eebe692174/giraffe/src/utils/formatters.ts)
 */

export const siPrefixFormatter =
  ({
    decimalPlaces = 0,
    trimZeros = true,
    localeFormat,
  }: SIPrefixFormatter): ((val: number) => string) =>
  (val: number) =>
    localeFormat.formatPrefix(`.${decimalPlaces}${trimZeros ? "~" : ""}`, val)(val)

export const customValueFormatter =
  ({
    prefix = "",
    suffix = "",
    decimalPlaces = 0,
    trimZeros = true,
    formatUnits = true,
    locale = "en",
  }: CustomValueFormatter): ((value: number) => string) =>
  (value: number) =>
    valueFormatter({
      value,
      prefix,
      suffix,
      decimalPlaces,
      formatUnits,
      locale,
      trimZeros,
    })

export const valueFormatter = ({
  value,
  prefix = "",
  suffix = "",
  decimalPlaces = 0,
  trimZeros = true,
  formatUnits = true,
  locale = "en",
}: ValueFormatter): string => {
  const localeFormat = getLocaleFormat(locale)

  // format value according to locale and decimalPlaces
  const formatNumberOnly = (val: number) =>
    localeFormat.format(`,.${decimalPlaces}~f`)(val)

  const addSpace = (val: string) => val.replace(/(\d)(?=[^\d.,%])/g, "$1 ")

  if (formatUnits !== true || (Math.abs(value) >= 1000 && Math.abs(value) < 100000)) {
    return addSpace(`${prefix}${formatNumberOnly(value)}${suffix}`)
  } else {
    // code below shortens extremely large or small numbers (greater than septillion+) by
    // first converting number to SI format, then removing the SI unit to convert
    // number to scientific notation, and finally
    // adding yotta (Y) back
    if (value >= 1e30 || value <= -1e30) {
      const siFormattedValue = String(
        siPrefixFormatter({ decimalPlaces, trimZeros, localeFormat })(Math.abs(value)),
      ).slice(0, -1)
      return `${prefix}${value < 0 ? "-" : ""}${d3Format(`.${decimalPlaces}`)(
        Number(siFormattedValue),
      )} Y${suffix}`
    }

    return addSpace(
      `${prefix}${siPrefixFormatter({ decimalPlaces, trimZeros, localeFormat })(
        value,
      )}${suffix}`,
    )
  }
}
